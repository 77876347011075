/*** Intro ***/

.mco-content.intro {
	overflow: hidden;
	position: relative;
}
.mco-content.intro > .mco-container-headline h1 {
	color: #ffffff;
}
.mco-content.intro > .mco-container-headline + .mco-container-text {
	color: #ffffff;
	position: relative;
}
.mco-content.intro > .mco-container-headline + .mco-container-text:before {
	top: -1000px;
	left: -4000px;
	width: 8000px;
	bottom: -16px;
	content: "";
	z-index: -1;
	position: absolute;
	background: #20303d;
	pointer-events: none;
}
.mco-content.intro
	> .mco-container-headline
	+ .mco-container-text
	+ .mco-container-daily-routine {
	position: relative;
}
.mco-content.intro
	> .mco-container-headline
	+ .mco-container-text
	+ .mco-container-daily-routine:before {
	top: -200px;
	left: -4000px;
	width: 8000px;
	bottom: 0;
	content: "";
	z-index: -1;
	position: absolute;
	background: #20303d;
	pointer-events: none;
}
