/* Layout */

/* Button */

button > .MuiButton-label {
	margin-top: 1px;
}

/* Überschrift */

/*** Headline ***/

.mco-container-headline-1,
.mco-container-headline-2,
.mco-container-headline-3,
.mco-container-headline-4,
.mco-container-headline-5,
.mco-container-headline-6,
.mco-container-headline-7 {
	text-align: center;
}
.mco-container-headline-1 h1,
.mco-container-headline-2 h2,
.mco-container-headline-3 h3,
.mco-container-headline-4 h4,
.mco-container-headline-5 h5,
.mco-container-headline-6 h6 {
	color: var(--mco-palette-secondary-dark);
}
.mco-container-headline-7 span {
	color: var(--mco-palette-secondary-main);
}

/* Text */

.mco-container-text-1 .mco-text-01 p {
	text-align: center;
}

/* List */

.mco-container-list .mco-list-01__headline {
	color: var(--mco-palette-secondary-dark);
	margin-bottom: 32px;
	text-align: center;
}
.mco-container-list .mco-list-01__list-item svg {
	margin-right: 16px;
	margin-top: 3px;
}

/* Table */

.mco-table-01 .mco-table-01__headline {
	margin-bottom: 32px;
	text-align: center;
}
.mco-table-01 .mco-table-01__cell {
	font-size: 18px;
	font-weight: normal;
}
.mco-table-01 .mco-table-01__cell strong {
	font-weight: bold;
}
.mco-table-01 .MuiTableCell-head {
	background: var(--mco-palette-background-teaser-light);
}
.mco-table-01 .mco-table-01__swipePopUp p {
	color: #ffffff !important;
}
.mco-table-01 .mco-table-01__swipePopUp svg path {
	fill: #ffffff !important;
}
.mco-table-01 .mco-table-01__footer p {
	font-size: 12px;
}
@media all and (min-width: 1280px) {
	.mco-table-01 .mco-table-01__cell {
		font-size: 18px;
		font-weight: normal;
	}
}

/* Singleimage */

.mco-container-singleimage .mco-singleimage-01__content {
	background: var(--mco-palette-background-body-dark);
	padding: 16px 20px;
	text-align: center;
}
.mco-container-singleimage .mco-singleimage-01__content > p {
	color: var(--mco-palette-secondary-main);
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.15em;
	text-transform: uppercase;
}
.mco-container-singleimage .mco-singleimage-01__content > div {
	color: #ffffff;
	font-family: "Marat-Sans-Regular";
	font-weight: normal;
}
.mco-container-singleimage .mco-singleimage-01__content > p + div {
	margin-top: 8px;
}

@media all and (min-width: 600px) {
	.mco-container-singleimage .mco-singleimage-01 {
		display: grid;
		grid-template-rows: repeat(7, auto);
		grid-template-columns: auto;
	}
	.mco-container-singleimage .mco-singleimage-01__image {
		grid-row: 1 / 7;
		width: 100%;
		grid-column: 1 / 2;
	}
	.mco-container-singleimage .mco-singleimage-01__content {
		grid-row: 6 / 8;
		grid-column: 1 / 2;
		margin: 0 auto;
		max-width: 500px;
	}
}
@media all and (min-width: 1280px) {
	.mco-container-singleimage .mco-singleimage-01__content {
		grid-row: 5 / 8;
		max-width: 600px;
		padding: 40px;
	}
}
@media all and (min-width: 1920px) {
	.mco-container-singleimage .mco-singleimage-01__content {
		max-width: 732px;
		padding: 70px;
	}
}

/* Bild-Text Kombination */

/*** Variant Intro ***/
.mco-container-imagetext-1 {
	margin-top: -60px !important;
	padding-bottom: 32px;
	padding-top: 40px;
	position: relative;
	z-index: 2;
}
.mco-container-imagetext-1:before {
	background: #20303d;
	content: "";
	position: absolute;
	inset: 0;
	z-index: -1;
}
.mco-container-imagetext-1 .mco-imagetext-01__text {
	color: var(--mco-palette-text-secondary);
}
@media all and (min-width: 600px) {
	.mco-container-imagetext-1 {
		margin-top: -100px !important;
		padding-bottom: 48px;
		padding-top: 56px;
	}
}
@media all and (min-width: 960px) {
	.mco-container-imagetext-1 {
		margin-top: -200px !important;
	}
	.mco-container-imagetext-1:before {
		top: 99px;
	}
}
@media all and (min-width: 1280px) {
	.mco-container-imagetext-1 {
		margin-top: -250px !important;
	}
	.mco-container-imagetext-1:before {
		top: 149px;
	}
}
@media all and (min-width: 1920px) {
	.mco-container-imagetext-1 {
		margin-top: -275px !important;
		padding-bottom: 68px;
	}
	.mco-container-imagetext-1:before {
		top: 174px;
	}
}

.mco-container-imagetext > .mco-container {
	padding: 0;
}
.mco-container-imagetext .mco-imagetext-01 {
	display: block;
}
.mco-container-imagetext .mco-imagetext-01 {
	padding-bottom: 32px;
}
.mco-container-imagetext .mco-imagetext-01__image {
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
}
.mco-container-imagetext .mco-imagetext-01__image picture {
	height: 100%;
}
.mco-container-imagetext .mco-imagetext-01__title {
	color: var(--mco-palette-secondary-main);
	font-size: clamp(32px, 6vw, 55px);
}
.mco-container-imagetext .mco-imagetext-01__subtitle {
	margin-top: auto;
	padding-top: 16px;
}

@media all and (min-width: 600px) {
	.mco-container-imagetext .mco-imagetext-01 {
		display: flex;
		align-items: center;
	}
	.mco-container-imagetext .mco-imagetext-01__image {
		flex-basis: 50%;
		max-width: 50%;
		margin-bottom: 0;
	}
	.mco-container-imagetext .mco-imagetext-01__content {
		flex-basis: 50%;
		max-width: 50%;
		padding-left: 32px;
	}
	.mco-container-imagetext .mco--imagetext-01__wrapper--left .mco-imagetext-01__content {
		padding-left: 0;
		padding-right: 32px;
	}
}
@media all and (min-width: 1280px) {
	.mco-container-imagetext .mco-imagetext-01__content {
		padding-left: 70px;
		padding-right: 46px;
	}
	.mco-container-imagetext .mco-imagetext-01__wrapper--left .mco-imagetext-01__content {
		padding-left: 46px;
		padding-right: 70px;
	}
	.mco-container-imagetext .mco-imagetext-01__title {
		margin-bottom: 32px;
	}
}
@media all and (min-width: 1920px) {
	.mco-container-imagetext .mco-imagetext-01__content {
		padding-left: 140px;
		padding-right: 70px;
	}
	.mco-container-imagetext .mco-imagetext-01__wrapper--left .mco-imagetext-01__content {
		padding-left: 70px;
		padding-right: 140px;
	}
	.mco-container-imagetext .mco-imagetext-01__title {
		margin-bottom: 40px;
	}
}

/* References */

.mco-references-01 .mco-references-01__headline {
	margin-bottom: 24px;
	text-align: center;
}
.mco-references-01 a {
	align-items: center;
	display: flex;
}
.mco-references-01 a > p {
	font-size: 18px;
	padding-right: 8px;
	transition: color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		padding-right 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.mco-references-01 a:hover > p {
	color: var(--mco-palette-primary-main);
	padding-right: 12px;
}
.mco-references-01 a svg path {
	fill: var(--mco-palette-secondary-main);
	opacity: 0.5;
	transition: fill 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		opacity 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.mco-references-01 a:hover svg path {
	fill: var(--mco-palette-primary-main);
	opacity: 1;
}
.mco-references-02 .mco-references-02__list-item button {
	color: #ffffff;
	height: auto;
	padding: 6px 16px;
	text-align: left;
}
.mco-references-02 .mco-references-02__list-item button p {
	font-family: "Marat-Sans-Semibold";
	font-size: 18px;
	letter-spacing: 0.1em;
}

/* Video */

.mco-container-video .mco-video-02 {
	padding: 0 !important;
}
.mco-container-video .mco-video-02__content {
	border: 1px solid #bcb5ab;
	border-left: none;
	border-right: none;
}
.mco-container-video .mco-video-02__content h3 {
	color: var(--mco-palette-secondary-main);
	font-size: 35px;
}
.mco-container-video .mco-video-02__image-wrapper svg {
	fill: #ffffff;
}
.mco-container-video .mco-video-02__image-wrapper .mco-video-02__play-icon {
	opacity: 0.8;
	transition: opacity 500ms ease;
}
.mco-container-video .mco-video-02__image-wrapper:hover .mco-video-02__play-icon {
	opacity: 1;
}

@media all and (min-width: 960px) {
	.mco-video-02 > div {
		padding-right: 32px;
	}
	.mco-container-video .mco-video-02__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
}

/* Download */

.mco-container-download > div {
	border-bottom: 1px solid #bcb5ab;
	border-top: 1px solid #bcb5ab;
	flex: 1;
	padding-bottom: 8px;
	padding-top: 8px;
}

/* Dropdown */

.mco-container-dropdown .MuiAccordionDetails-root > div > .mco-container:first-child {
	margin-top: 0;
}
.mco-container-dropdown .mco-container {
	max-width: none;
}

/* Formular */

.mco-container-form .mco-form-01__item {
	margin-bottom: 16px !important;
}
.mco-container-form .mco-form-01__headline h4 {
	color: var(--mco-palette-secondary-dark);
	font-size: clamp(25px, 5vw, 35px);
}
.mco-container-form .mco-form-01__item label {
	color: var(--mco-palette-secondary-main);
}
.mco-form-01__checkbox-privacy svg path,
.mco-form-01__checkbox svg path {
	color: var(--mco-palette-secondary-main);
}
@media all and (max-width: 600px) {
	.mco-form-01 .mco-form-01__button button {
		font-size: 16px;
		height: 38px;
		line-height: 1;
	}
}

/* Teaser Slider */

@media all and (min-width: 960px) {
	.mco-container-teaser-list-custom-with-marks {
		margin-bottom: -120px;
	}
}

/* Gallery */

.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div {
	overflow: hidden;
}
.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:before {
	background: linear-gradient(-180deg, rgba(32, 48, 61, 0) 54.66%, #20303d 98.41%);
	content: "";
	opacity: 0;
	transition: opacity 500ms ease;
	position: absolute;
	inset: 0;
	z-index: 1;
}
.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:after {
	bottom: 14px;
	content: "zoom";
	color: #ffffff;
	font-size: 14px;
	opacity: 0;
	transition: opacity 500ms ease;
	position: absolute;
	text-transform: uppercase;
	left: 16px;
	z-index: 1;
}
.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div img {
	transition: transform 800ms ease;
}
.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__download button {
	font-size: 16px;
}

@media (hover: hover) {
	.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:hover:before {
		opacity: 1;
	}
	.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:hover:after {
		opacity: 1;
	}
	.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:hover img {
		transform: scale(1.1) rotate(5deg);
	}
}

.mco-container-gallery .mco-gallery-04 .swiper-container {
	padding-bottom: 48px;
}
.mco-container-gallery .mco-gallery-04 .mco-gallery-04-swiper-nav__prev,
.mco-container-gallery .mco-gallery-04 .mco-gallery-04-swiper-nav__next {
	top: calc(50% - 20px);
	transition: background 500ms ease;
}
.mco-container-gallery .mco-gallery-04 .mco-gallery-04-swiper-nav__prev:hover,
.mco-container-gallery .mco-gallery-04 .mco-gallery-04-swiper-nav__next:hover {
	background: rgba(0, 0, 0, 0.4);
}
.mco-container-gallery .mco-gallery-04 .swiper-pagination {
	bottom: 0;
}
.mco-container-gallery .mco-gallery-04 .swiper-pagination .swiper-pagination-bullet {
	background: var(--mco-palette-secondary-main);
	border-radius: var(--mco-shape-border-radius);
	height: 12px;
	margin: 0 5px;
	transform: rotate(45deg);
	width: 12px;
}
.mco-container-gallery .mco-gallery-04 .swiper-pagination .swiper-pagination-bullet-active {
	background: var(--mco-palette-primary-main);
}
.mco-container-gallery .swiper-slide img {
	border-radius: var(--mco-shape-border-radius);
}

/* Teaser */

.mco-container-teaser-list .swiper-container {
	overflow: visible;
}
.mco-container-teaser-list .swiper-pagination {
	bottom: 0 !important;
	position: relative !important;
}
.mco-container-teaser-list .swiper-pagination .swiper-pagination-bullet {
	background: var(--mco-palette-secondary-main);
	border-radius: var(--mco-shape-border-radius);
	height: 12px;
	margin: 0 5px;
	transform: rotate(45deg);
	width: 12px;
}
.mco-container-teaser-list .swiper-pagination .swiper-pagination-bullet-active {
	background: var(--mco-palette-primary-main);
}
.mco-teaser-01 {
	background: var(--mco-palette-background-teaser-light) !important;
	border-bottom-left-radius: var(--mco-shape-border-radius);
	border-bottom-right-radius: var(--mco-shape-border-radius);
}
.mco-teaser-01 .mco-teaser-01__title {
	color: var(--mco-palette-secondary-dark) !important;
	font-family: "Marat-Regular";
	font-size: 28px;
}
.mco-teaser-01 .mco-teaser-01__content button {
	padding-left: 0;
	padding-right: 0;
	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.mco-teaser-01 .mco-teaser-01__content button:hover {
	background: none !important;
	color: var(--mco-palette-primary-main);
}
.mco-teaser-01 img {
	border-top-left-radius: var(--mco-shape-border-radius);
	border-top-right-radius: var(--mco-shape-border-radius);
}
@media all and (min-width: 600px) {
	.mco-container-teaser-list .swiper-container {
		overflow: hidden;
	}
}

/* Teaser Room */

.mco-teaser-room-02 .mco-teaser-room-02__image-root img {
	aspect-ratio: 760 / 505;
}
.mco-teaser-room-02 .mco-teaser-room-02__title {
	font-size: 30px;
	margin-bottom: 0;
}
.mco-teaser-room-02 .mco-teaser-room-02__addition {
	font-family: "Marat-Sans-Regular";
	font-size: 22px;
	font-weight: 400;
}

@media all and (min-width: 960px) {
	.mco-teaser-room-02 .mco-teaser-room-02__facts {
		max-width: 275px;
	}
	.mco-teaser-room-02 .mco-teaser-room-02__addition {
		font-family: "Marat-Regular";
	}
	.mco-teaser-room-02 .mco-teaser-room-02__facts-item span {
		font-family: "Marat-Regular";
		font-size: 20px;
	}
}
@media all and (min-width: 1280px) {
	.mco-teaser-room-02 {
		grid-column-gap: 48px !important;
	}
	.mco-teaser-room-02 .mco-teaser-room-02__title {
		font-size: 35px;
		line-height: 1.2;
		margin-bottom: 8px;
	}
	.mco-teaser-room-02 .mco-teaser-room-02__addition {
		font-size: 25px;
		line-height: 1.2;
		margin-bottom: 20px;
	}
	.mco-teaser-room-02 .mco-teaser-room-02__facts-item span {
		font-size: 25px;
		line-height: normal;
	}
}

/* Singleteaser */

.mco-singleteaser-01 .mco-singleteaser-01__content {
	background: var(--mco-palette-background-body-dark);
	border: none;
}
.mco-singleteaser-01 .mco-singleteaser-01__title {
	font-family: "Marat-Sans-Regular";
	font-weight: normal;
	font-size: 18px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	margin-bottom: 16px;
}
.mco-singleteaser-01 .mco-singleteaser-01__text {
	color: #f5f1eb;
}
.mco-singleteaser-01 .mco-singleteaser-01__content button {
	background: none;
	color: var(--mco-palette-secondary-main);
	font-size: 1.2767857142857142rem;
	height: 36px;
	margin-left: auto;
	padding-left: 0;
	padding-right: 0;
	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	width: auto;
}
.mco-singleteaser-01 .mco-singleteaser-01__content button:hover {
	background: none !important;
	color: var(--mco-palette-primary-main);
}
@media all and (min-width: 960px) {
	.mco-singleteaser-01 .mco-singleteaser-01__title {
		font-size: 22px;
	}
}

/* Ski - Liftstatus */

.mco-liftstate {
	margin-left: -16px;
	margin-right: -16px;
}
.mco-liftstate .mco-liftstate__table-row .MuiTypography-subtitle1 {
	color: var(--mco-palette-text-primary);
}
.mco-liftstate .mco-liftstate__table-cell svg path {
	fill: var(--mco-palette-secondary-main);
}
.mco-liftstate .mco-liftstate__filter--open span > p,
.mco-liftstate .mco-liftstate__filter--open > span {
	color: var(--mco-palette-primary-main);
}
.mco-liftstate .mco-liftstate__filter--all span > p,
.mco-liftstate .mco-liftstate__filter--all > span {
	color: var(--mco-palette-secondary-main);
}
.mco-liftstate .mco-liftstate__table-head th {
	background: #f5f1eb;
	border-bottom: none;
}
.mco-liftstate .mco-liftstate__table-row td {
	border-bottom: 0.5px solid #f5f1eb;
}
.mco-liftstate .mco-liftstate__update-info {
	padding-left: 16px;
	padding-right: 16px;
}

@media all and (min-width: 600px) {
	.mco-liftstate {
		margin-left: 0;
		margin-right: 0;
	}
	.mco-liftstate .mco-piststate__update-info {
		padding-left: 0;
		padding-right: 16px;
	}
}

/* E-Book */

.mco-ebook-teaser-01 {
	background: #f5f1eb !important;
	border-radius: 4px !important;
}
.mco-ebook-teaser-01 .mco-ebook-teaser-01__image {
	border-radius: 4px !important;
}
.mco-ebook-teaser-01 .mco-ebook-teaser-01__buttons button {
	height: 36px;
	letter-spacing: 0.1em;
	font-size: 16px;
}
.mco-ebook-teaser-01 .mco-ebook-teaser-01__buttons button .MuiButton-startIcon {
	margin-left: 0 !important;
}
.mco-ebook-teaser-01 .mco-ebook-teaser-01__buttons button svg {
	width: 16px !important;
}
.mco-ebook-teaser-01 .mco-ebook-teaser-01__buttons .mco-ebook-teaser-01__browse button {
	color: #ffffff;
}
@media all and (min-width: 960px) {
	.mco-ebook-teaser-01 .mco-ebook-teaser-01__buttons button {
		height: 42px;
		font-size: 18px;
	}
}

/* Ski - Pistenstatus */

.mco-piststate {
	margin-left: -16px;
	margin-right: -16px;
}
.mco-piststate .mco-piststate__table-row .MuiTypography-subtitle1 {
	color: var(--mco-palette-text-primary);
}
.mco-piststate .mco-piststate__filter--open span > p,
.mco-piststate .mco-piststate__filter--open > span {
	color: var(--mco-palette-primary-main);
}
.mco-piststate .mco-piststate__filter--all span > p,
.mco-piststate .mco-piststate__filter--all > span {
	color: var(--mco-palette-secondary-main);
}
.mco-piststate .mco-piststate__table-head th {
	background: #f5f1eb;
	border-bottom: none;
}
.mco-piststate .mco-piststate__table-row td {
	border-bottom: 0.5px solid #f5f1eb;
}
.mco-piststate .mco-piststate__update-info {
	padding-left: 16px;
	padding-right: 16px;
}

@media all and (min-width: 600px) {
	.mco-piststate {
		margin-left: 0;
		margin-right: 0;
	}
}

/* Ski - Wetter */

.mco-weater-02 .mco-weater-02-day {
	background: #f5f1eb;
}
.mco-weater-02 .mco-weater-02-day h2 {
	font-size: 35px;
}
.mco-weater-02 .mco-weater-02-day__times-wrapper {
	margin-top: 24px;
}

@media all and (min-width: 1280px) {
	.mco-weater-02 .mco-weater-02-day h2 {
		font-size: 50px;
	}
}

/* Routenplaner */

.mco-container-route-planner {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.mco-container-route-planner .leaflet-interactive {
	stroke: rgba(232, 122, 116, 0.7);
}

/* Overlay */

.mco-overlay .mco-overlay-toolbar {
	background: var(--mco-palette-background-body-dark);
}
.mco-overlay .mco-overlay-toolbar-title {
	color: #ffffff;
}
.mco-overlay .mco-overlay-toolbar svg path {
	fill: #ffffff;
}
.mco-overlay .mco-overlay-iframe-wrapper img {
	height: 100%;
	object-fit: contain;
	width: 100%;
}
.mco-overlay .mco-overlay-arrow-next {
	align-items: center;
	background: linear-gradient(90deg, rgba(32, 48, 61, 0.2) 54.66%, #20303d 98.41%);
	display: flex;
	height: 100px;
	right: 0 !important;
	z-index: 1;
}
.mco-overlay .mco-overlay-arrow-prev {
	align-items: center;
	background: linear-gradient(-90deg, rgba(32, 48, 61, 0.2) 54.66%, #20303d 98.41%);
	display: flex;
	height: 100px;
	left: 0 !important;
	z-index: 1;
}

/* Ski - Snowreport */

.mco-snowreport-01 svg path {
	fill: var(--mco-palette-secondary-main) !important;
}

/* Around Me */

.mco-content.map {
	padding-top: 80px !important;
}
.submenu--open .mco-map {
	z-index: -1;
}
.mco-map .MuiSelect-root {
	font-size: 18px;
	line-height: normal;
}
.mco-around-me__intro {
	text-align: center !important;
}
.mco-around-me__intro h2 {
	font-size: clamp(25px, 5vw, 35px) !important;
}
.mco-map .mco-map-filter header {
	background: var(--mco-palette-primary-main);
}
.mco-map-filter__content {
	gap: 8px;
}
.mco-map-filter__content .MuiCheckbox-root {
	padding: 8px;
}
.mco-map-filter__content .MuiCheckbox-root .MuiSvgIcon-root {
	fill: var(--mco-palette-secondary-light);
	height: 27px;
	width: 27px;
}
.mco-map .mco-map-filter .mco-map-filter__item p {
	font-size: 16px;
}
.mco-around-me__teaser-wrapper {
	background: #f5f1eb !important;
}
.leaflet-popup-content__custom-bottom {
	background: #f5f1eb !important;
}
.mco-around-me__teaser-button button {
	background: var(--mco-palette-secondary-main) !important;
	font-size: 16px !important;
}
.mco-around-me__teaser-street,
.mco-around-me__teaser-town {
	line-height: 1.2 !important;
}
.mco-around-me__teaser-links a span {
	line-height: 1.2 !important;
}
.leaflet-popup-content__custom-phone {
	background: var(--mco-palette-secondary-main) !important;
}

/* Lang Selection */

.mco-overlay.overlay-lang .mco-overlay-toolbar {
	background: var(--mco-palette-primary-main) !important;
}
.mco-language-switch__entry a:hover {
	background: var(--mco-palette-primary-main) !important;
}

/* Other */

.MuiInputBase-root .MuiSelect-icon {
	height: 24px;
	width: 24px;
}

@media all and (min-width: 1280px) {
	.mco-content.map {
		padding-top: 100px !important;
	}
}

/* Consent-Management */

.mco-consent-pop-up,
.mco-overlay-content {
	border-radius: 4px !important;
}
.mco-consent-configuration__toolbar {
	background: var(--mco-palette-background-teaser-light) !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
}
.mco-consent-configuration__toolbar p {
	color: #20303d !important;
}
.mco-consent-configuration__groups > div .MuiAccordionSummary-root {
	background: var(--mco-palette-background-teaser-light) !important;
}
.mco-consent-configuration__groups .MuiAccordionDetails-root > div > div {
	padding-top: 4px !important;
}
.mco-consent button {
	box-shadow: none !important;
	border-radius: 4px !important;
	font-family: "Marat-Sans-Semibold" !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	height: 48px !important;
	letter-spacing: 0.1em !important;
	line-height: 1 !important;
	padding: 10px 15px !important;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
.mco-consent button.MuiButton-outlined {
	padding: 9px 15px !important;
}
.mco-consent-pop-up__button-confirm:hover {
	background: var(--mco-palette-primary-dark) !important;
}
.mco-consent-pop-up__text {
	font-size: 18px !important;
	text-align: center !important;
}
.mco-consent-configuration__text {
	font-size: 18px !important;
}
.mco-consent-pop-up__privacy {
	color: var(--mco-palette-primary-main) !important;
}
.mco-consent-pop-up__privacy:hover {
	color: var(--mco-palette-primary-dark) !important;
}

/* Interaktionslayer */

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	background: var(--mco-palette-primary-main);
	border: 0;
	border-radius: 4px;
	box-shadow: none !important;
	cursor: pointer;
	font-family: "Marat-Sans-Semibold";
	font-size: 20px;
	font-weight: 600;
	height: 48px;
	letter-spacing: 0.1em;
	line-height: 48px;
	min-width: 64px;
	padding: 0 24px;
	text-align: center;
	text-transform: uppercase;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: var(--mco-palette-primary-dark);
}
.micado-ial-item-notification__button {
	border-radius: 4px;
}
.micado-ial__notification .micado-ial__close:hover .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close:hover .micado-svg-icon svg path {
	fill: #ffffff;
}
.micado-ial-item-notification__button--is-icon:hover {
	background: var(--mco-palette-primary-dark);
}
.micado-ial__close:hover {
	background: #bcb5ab !important;
}
.micado-ial-item-message__buttons {
	justify-content: flex-end !important;
}

/* Ticket-Shop */

.mco-ski-ticket-shop button {
	font-size: 18px !important;
}
.mco-ski-ticket-shop-filter__step .mco-form-date label {
	color: var(--mco-palette-secondary-main) !important;
}
.mco-ski-ticket-shop-filter__step .mco-form-date input {
	padding-bottom: 15px;
	padding-top: 15px;
}
.mco-ski-ticket-shop-teaser,
.mco-ski-ticket-shop-cart-product {
	background: var(--mco-palette-background-teaser-light) !important;
}
.mco-ski-ticket-shop-teaser__fallback-image {
	background: var(--mco-palette-background-teaser-dark) !important;
}
.mco-ski-ticket-shop-teaser__fallback-image svg path {
	fill: #ffffff !important;
}
.mco-ski-ticket-shop__quantity input {
	padding-bottom: 7px !important;
	padding-top: 7px !important;
}
.mco-ski-ticket-shop__cart-button .MuiBadge-badge {
	background: #20303d !important;
	color: #ffffff !important;
}
.mco-ski-ticket-shop-cart__total-price {
	background: var(--mco-palette-background-teaser-dark) !important;
}
.mco-ski-ticket-shop-cart__total-price p {
	color: #ffffff !important;
}
.mco-ski-ticket-shop-cart__buttons button {
	font-size: 18px;
}
