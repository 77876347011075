.room-detail .mco-content > .mco-container-headline:first-child * {
	color: #ffffff;
}
.room-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-gallery {
	margin-top: 40px;
	position: relative;
}
.room-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-gallery {
	margin-top: 40px;
	position: relative;
}
.room-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-gallery::before {
	background: var(--mco-palette-background-body-dark);
	content: "";
	height: 2000px;
	left: -4000px;
	position: absolute;
	bottom: 30%;
	width: 8000px;
	z-index: -1;
}
