.blog-detail .mco-content > .mco-container-headline {
	margin-top: 60px !important;
}
.blog-detail .mco-content > .mco-container-headline h1 {
	font-size: clamp(35px, 7vw, 70px);
}
.blog-detail .mco-content > .mco-container-headline:first-child * {
	color: #ffffff;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-gallery {
	position: relative;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-text
	+ .mco-container-gallery {
	position: relative;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-blog-detail-info
	+ .mco-container-text
	+ .mco-container-gallery {
	position: relative;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-blog-detail-info
	+ .mco-container-gallery {
	position: relative;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-blog-detail-info
	+ .mco-container-singleimage {
	position: relative;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-gallery::before {
	background: var(--mco-palette-background-body-dark);
	content: "";
	height: 2000px;
	left: -4000px;
	position: absolute;
	bottom: 30%;
	width: 8000px;
	z-index: -1;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-blog-detail-info
	+ .mco-container-gallery::before {
	background: var(--mco-palette-background-body-dark);
	content: "";
	height: 2000px;
	left: -4000px;
	position: absolute;
	bottom: 30%;
	width: 8000px;
	z-index: -1;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-blog-detail-info
	+ .mco-container-singleimage::before {
	background: var(--mco-palette-background-body-dark);
	content: "";
	height: 2000px;
	left: -4000px;
	position: absolute;
	bottom: 30%;
	width: 8000px;
	z-index: -1;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-blog-detail-info
	+ .mco-container-text
	+ .mco-container-gallery::before {
	background: var(--mco-palette-background-body-dark);
	content: "";
	height: 2000px;
	left: -4000px;
	position: absolute;
	bottom: 70%;
	width: 8000px;
	z-index: -1;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-blog-detail-info {
	color: #ffffff;
	margin-top: 32px;
}
.blog-detail
	.mco-content
	> .mco-container-headline
	+ .mco-container-spacing
	+ .mco-container-blog-detail-info
	+ .mco-container-text {
	color: #ffffff;
	margin-top: 60px;
	text-align: center;
}
.blog-detail .mco-container-headline h2,
.blog-detail .mco-container-headline h3,
.blog-detail .mco-container-headline h4,
.blog-detail .mco-container-headline h5,
.blog-detail .mco-container-headline h6 {
	color: var(--mco-palette-secondary-main);
	text-align: left;
}
