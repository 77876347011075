.mco-container-headline + .mco-container-headline {
	margin-top: 28px;
}
.mco-container-headline-7 + .mco-container-headline {
	margin-top: 8px;
}
.mco-container-headline + .mco-container-spacing {
	margin-top: 20px;
}
.mco-container-headline + .mco-container-text {
	margin-top: 28px;
}
.mco-container-download + .mco-container-download {
	margin-top: 24px;
}
.mco-container-ebook + .mco-container-ebook {
	margin-top: 56px;
}
.mco-container-table + .mco-container-table {
	margin-top: 56px;
}
.mco-container-singleteaser + .mco-container-singleteaser {
	margin-top: 56px;
}
.mco-container-external-singleteaser + .mco-container-external-singleteaser {
	margin-top: 56px;
}
.mco-container-headline + .mco-container-dropdown {
	margin-top: 40px;
}
.mco-container-dropdown + .mco-container-dropdown {
	margin-top: -16px;
}
.mco-container-spacing + .mco-container-teaser-list-custom,
.mco-container-spacing + .mco-container-teaser-list-custom-with-marks {
	margin-top: 0;
}
.mco-content > .mco-container-daily-routine:first-child {
	margin-top: -60px !important;
}

@media all and (min-width: 600px) {
	.mco-content > .mco-container-daily-routine:first-child {
		margin-top: -100px !important;
	}
}
